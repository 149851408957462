import React, { useState } from 'react';
import cogoToast from 'cogo-toast';
import { PayPalButtons } from '@paypal/react-paypal-js';

const PayPalButton = ({ service, disabled, paymentByPayPal }) => {
    const [paidFor, setPaidFor] = useState(false);

    // Assuming service contains price in pounds and a description
    const serviceDescription = service?.description || "Airport Cab Fare"; 
    const amount = service?.price || 150; // Default price of £1.50 if not provided
    const currency= "GBP";

    return (
        <div className="paypal-button-container">
            <PayPalButtons
                disabled={disabled}
                style={{
                    color: "black",
                    layout: "horizontal",
                    height: 35,
                    width: 40,
                    tagline: false,
                    shape: "rect",
                    /* label: 'paypal', */
                    transform: 'translate(-50%, -15%)'
                }}
                onClick={(data, actions) => {
                    const hasAlreadyBookedService = false;
                    if (hasAlreadyBookedService) {
                        cogoToast.error("You already booked this service. Go to your account to view your list of services.", { position: 'bottom-center' });
                        return actions.reject();
                    } else {
                        return actions.resolve();
                    }
                }}
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            description: serviceDescription,
                            amount: {
                                value: amount,
                                currency_code: currency
                            }
                        }]
                    });
                }}
                onApprove={async (data, actions) => {
                    const order = await actions.order.capture();
                    console.log("order", order);
                    if (order.status==='COMPLETED'){                        
                        setPaidFor(true);
                        paymentByPayPal(order);
                    }                    
                }}
                onCancel={() => {
                    console.error("Paypal checkout onCancel.");
                }}
                onError={(err) => {
                    console.error("Paypal checkout onError. ", err);
                    cogoToast.error("Paypal checkout onError. " + err, { position: 'bottom-center' });
                }}
            />
        </div>
    );
};

export default PayPalButton;
