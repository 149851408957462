import React, { useState } from 'react';
import StripeCheckout from 'react-stripe-checkout';

const StripeCheckoutButton = ({ service, disabled, paymentByStripe, stripeKey }) => {
    const [paidFor, setPaidFor] = useState(false);

    // Assuming service contains price in pounds and a description
    const serviceDescription = service?.description || "Airport Cab Fare"; 
    const amount = service?.price || 150; // Default price of £1.50 if not provided
    const currency= "GBP";

    return (
        <div className="stripe-button-container">
            <StripeCheckout
                disabled={disabled} 
                token={(token) => {
                    //console.log(token);
                    if (token !== null) {
                        paymentByStripe(token);
                    }
                }}               
                name="Airport Cab - MyBoogy"
                description={serviceDescription}
                panelLabel="Pay"
                currency={currency}
                amount={amount * 100}  // Stripe expects amount in pence
                stripeKey={stripeKey}
            />
        </div>
    );
};

export default StripeCheckoutButton;
