import React, { Component } from 'react';/* 
import ApiURL from "../../api/ApiURL";
import Slider from 'react-slick'; */

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            TestimonialSliderData: []
        }
    }

    componentDidMount() {
        const testimonialSliderDataList = [
            {
                ClientImageName: "https://i.ibb.co/8x9xK4H/team.jpg",
                Feedback: "I was looking for an airport sallon car. Suddenly found out about this company on Instagram. It took hardly a minute to get a quotation. On the day of my journey, the cab arrived on time and the entire journey was taken care of. Cheers.",
                ClientName: "Client 1"
            },
            {
                ClientImageName: "https://i.ibb.co/8x9xK4H/team.jpg",
                Feedback: "I was looking for an airport sallon car. Suddenly found out about this company on Instagram. It took hardly a minute to get a quotation. On the day of my journey, the cab arrived on time and the entire journey was taken care of. Cheers.",
                ClientName: "Client 2"
            },
            {
                ClientImageName: "https://i.ibb.co/8x9xK4H/team.jpg",
                Feedback: "I was looking for an airport sallon car. Suddenly found out about this company on Instagram. It took hardly a minute to get a quotation. On the day of my journey, the cab arrived on time and the entire journey was taken care of. Cheers.",
                ClientName: "Client 3"
            },
            {
                ClientImageName: "https://i.ibb.co/8x9xK4H/team.jpg",
                Feedback: "I was looking for an airport sallon car. Suddenly found out about this company on Instagram. It took hardly a minute to get a quotation. On the day of my journey, the cab arrived on time and the entire journey was taken care of. Cheers.",
                ClientName: "Client 4"
            },
            {
                ClientImageName: "https://i.ibb.co/8x9xK4H/team.jpg",
                Feedback: "I was looking for an airport sallon car. Suddenly found out about this company on Instagram. It took hardly a minute to get a quotation. On the day of my journey, the cab arrived on time and the entire journey was taken care of. Cheers.",
                ClientName: "Client 5"
            }

        ];

        this.setState({ TestimonialSliderData: testimonialSliderDataList })
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const sliderList = this.state.TestimonialSliderData;
        const MyView = sliderList.length > 0 ?
            (sliderList.map((sliderItem, i) => {
                //const sliderImgURL = ApiURL.ClientTestimonialImageURL + "/" + sliderItem.ClientImageName;
                const sliderImgURL = sliderItem.ClientImageName;
                return (
                    <div class="carousel-item">
                        <div className="testimonial4_slide" key={i}>
                            <img src="https://i.ibb.co/8x9xK4H/team.jpg" className="img-circle img-responsive" />
                            <p>{sliderItem.Feedback}</p>
                            <h4>{sliderItem.ClientName}</h4>
                        </div>
                    </div>
                )
            })) :
            (<div></div>)

        return (
            <>
                <section className="testimonial text-center">
                    <div className="container">
                        <div className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x">

                            <div className="carousel-inner" role="listbox">
                                <div className="carousel-item active">
                                    <div className="testimonial4_slide">
                                        <img src="https://i.ibb.co/8x9xK4H/team.jpg" className="img-circle img-responsive" />
                                        <p>I was looking for an airport sallon car. Suddenly found out about this company on Instagram. It took hardly a minute to get a quotation. On the day of my journey, the cab arrived on time and the entire journey was taken care of. Cheers.</p>
                                        <h4>Client 1</h4>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section >
            </>
        );
    }
}

export default Testimonial;