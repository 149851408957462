import React, { Component } from 'react';
import Login from '../components/common/Login';
import MainHeader from '../components/common/MainHeader';
import MainFooter from '../components/common/MainFooter';

class LoginPage extends Component {
    render() {
        return (
            <>
                <MainHeader />

                <Login />

                <MainFooter />
            </>
        );
    }
}

export default LoginPage;