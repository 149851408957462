import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import Unauthorized from '../components/common/Unauthorized';
import MainFooter from '../components/common/MainFooter';

class UnauthorizedPage extends Component {
    render() {
        return (
            <>
                <MainHeader />

                <Unauthorized />
                
                <MainFooter />
            </>
        );
    }
}

export default UnauthorizedPage;