import React, { Component } from 'react';
import aboutImage from '../../assets/images/about.png';

class HomeAboutUs extends Component {
    render() {
        return (
            <>
                <section className="section_all">
                    <div className="container">
                        <div className="row vertical_content_manage mt-5">
                            <div className="col-lg-6">
                                <div className="about_header_main mt-3">
                                    <div className="titlepage">
                                        <h2>About us</h2>
                                    </div>
                                    <h4 className="about_heading text-capitalize font-weight-bold mt-4">MyBoogy Cab offers the cheapest airport pick-ups and drops in the UK.</h4>
                                    <p className="text-muted mt-3">MyBoogy, is a start-up company in the UK, that has initiated its operations, since 2023. MyBoogy Airport  Cab connects cab operators with passengers via this platform.
                                        Passengers from all over the globe can book a cab using our platform, to experience a hassle-free and timely ride to the destination.</p>

                                    <p className="text-muted mt-3">The cars are operated by licensed operators, and passengers and vehicles are fully insured. Go for instant quotations and find out the lowest fares from your location to the airport.</p>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="img_about mt-3">
                                    <img src={aboutImage} alt="..." className="img-fluid mx-auto d-block"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HomeAboutUs;