import React, { Component } from 'react';
import estateCarImage from '../../assets/images/estate-car.png';
import saloonCarImage from '../../assets/images/saloon-car.png';
import executiveCarImage from '../../assets/images/Executive-car.png';
import peopleCarImage from '../../assets/images/people-car.png';
import miniBusImage from '../../assets/images/mini-bus.png';

class OurVehicles extends Component {
    render() {
        return (
            <>
                <div id="service" className="service">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <div className="titlepage">
                                    <h2>Our Vehicles</h2>
                                    <p>There are 5 different sizes of vehicles with different capacities for the clients to choose from.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="service_main">
                                    <div className="service_box yelldark_colo">
                                        <i><img src={estateCarImage} alt="..." /></i>
                                        <h4>Estate</h4>
                                    </div>
                                    <div className="service_box yelldark_colo">
                                        <i><img src={saloonCarImage} alt="..." /></i>
                                        <h4>Sallon</h4>
                                    </div>
                                    <div className="service_box yelldark_colo">
                                        <i><img src={executiveCarImage} alt="..." /></i>
                                        <h4> Executive Car</h4>
                                    </div>
                                    <div className="service_box yelldark_colo">
                                        <i><img src={peopleCarImage} alt="..." /></i>
                                        <h4>People Carrier</h4>
                                    </div>
                                    <div className="service_box yelldark_colo">
                                        <i><img src={miniBusImage} alt="..." /></i>
                                        <h4>8 Seater</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default OurVehicles;