import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import { Redirect } from 'react-router';


class JourneyDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: 'Proceed To Pay',
            countryCodes: [],
            /* Lead Passanger Detail */
            custUserId: 0,
            userId: 0,
            firstName: '',
            lastName: '',
            email: '',
            mobileCode: '',
            mobile: '',
            /* Trip Details */
            pickOrDrop: '',
            postCode: '',
            terminalId: 0,
            terminalName: '',
            carId: 0,
            returnJourney: false,
            address: '',
            journeyDate: '',
            journeyTime: '',
            noOfPassenger: '',
            noOfCheckInBaggage: '',
            noOfCabinBaggage: '',
            noteForDriver: '',
            flightNumber: '',
            /* Return Trip Details */
            returnDate: '',
            returnTime: '',
            returnFlightNumber: '',
            meetAndGreetServiceTaken: false,
            babySeatRequired: false,
            /* Journey Rate */
            oneWayAmount: 0,
            discountOneWayAmount: 0,
            returnAmount: 0,
            discountReturnAmount: 0,
            redirectToPayment: false
        }
    }

    componentDidMount() {
        this.getCountryCodes();
        /* Get User Profile Data */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({
                custUserId: userData.UserId,
                userId: userData.UserId,
                email: userData.Email,
                mobile: userData.Mobile
            })
        }
        else {
            /* TODO: Redirect to the Login Page */
        }

        /* Get Quotation Request Data */
        var quoteReqData = JSON.parse(LocalStorageHelper.getQuoteRequest());
        if (quoteReqData !== null) {
            console.log(quoteReqData);
            this.setState({
                pickOrDrop: quoteReqData.PickOrDrop,
                postCode: quoteReqData.PostCode,
                terminalId: quoteReqData.TerminalId,
                terminalName: quoteReqData.TerminalName,
                carId: quoteReqData.CarId,
                returnJourney: quoteReqData.ReturnJourney,
                oneWayAmount: quoteReqData.OneWayRate,
                discountOneWayAmount: quoteReqData.DiscountOneWayRate,
                returnAmount: quoteReqData.ReturnRate,
                discountReturnAmount: quoteReqData.DiscountReturnRate,
            })
        }
    }

    onChangeHandler = (event) => {
        console.log(event.target.checked);
        if (event.target.name === 'meetAndGreetServiceTaken') {
            this.setState({
                ...this.state,
                meetAndGreetServiceTaken: event.target.checked
            })
        }
        else if (event.target.name === 'babySeatRequired') {
            this.setState({
                ...this.state,
                babySeatRequired: event.target.checked
            })
        }
        else {
            this.setState({
                ...this.state,
                [event.target.name]: event.target.value
            })
        }
    }

    getCountryCodes = () => {
        axios.get(ApiURL.GetAllCountryCode).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult.length > 0) {
                    this.setState({
                        ...this.state,
                        countryCodes: dataResult
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    submitForPayment = (e) => {
        e.preventDefault();
        var errors = [];
        if (this.state.firstName === '') {
            errors.push("First Name is required!");
            //cogoToast.error("First Name is required!", { position: 'bottom-center' });
        }
        if (this.state.lastName === '') {
            errors.push("Last Name is required!");
        }
        if (this.state.email === '') {
            errors.push("Email is required!");
        }
        if (this.state.mobileCode === '') {
            errors.push("Mobile Code is required!");
        }
        if (this.state.mobile === '') {
            errors.push("Mobile number is required!");
        }
        if (this.state.postCode === '') {
            errors.push("Postcode is required!");
        }
        if (this.state.journeyDate === '') {
            errors.push("Journey Date is required!");
        }
        if (this.state.journeyTime === '') {
            errors.push("Journey Time is required!");
        }
        if (this.state.noOfPassenger === '') {
            errors.push("Number of Passenger is required!");
        }
        if (this.state.flightNumber === '') {
            errors.push("Flight Number is required!");
        }

        if (errors.length > 0) {
            cogoToast.error(
                <div>
                    <b>Errors</b><hr />
                    <div>
                        <ul>
                            {
                                errors.map((err, index) => <li>{++index}. {err}</li>)
                            }
                        </ul>
                    </div>
                </div>
            );
        }
        else {

            this.setState({ btn: 'Processing...' })
            let isPickup = this.state.pickOrDrop ? 1 : 0,
                isRetJourney = this.state.returnJourney ? 1 : 0,
                retJourneyDate = '',
                retJourneyTime = '',
                retFlightNumber = '';

            if (isRetJourney > 0) {
                retJourneyDate = this.state.returnDate;
                retJourneyTime = this.state.returnTime;
                retFlightNumber = this.state.returnFlightNumber;
            }

            let param = {
                CustomerUserId: this.state.custUserId,
                FirstName: this.state.firstName,
                LastName: this.state.lastName,
                Email: this.state.email,
                MobileCode: this.state.mobileCode,
                Mobile: this.state.mobile,
                Address: this.state.address,
                PostCode: this.state.postCode,
                JourneyDate: this.state.journeyDate,
                JourneyTime: this.state.journeyTime,
                NoOfPassenger: parseInt(this.state.noOfPassenger),
                NoOfCheckInBaggage: parseInt(this.state.noOfCheckInBaggage),
                NoOfCabinBaggage: parseInt(this.state.noOfCabinBaggage),
                NoteForDriver: this.state.noteForDriver,
                FlightNumber: this.state.flightNumber,
                MeetAndGreetServiceTaken: this.state.meetAndGreetServiceTaken,
                BabySeatRequired: this.state.babySeatRequired,
                IsPickup: isPickup,
                IsReturnJourney: isRetJourney,
                ReturnJourneyDate: retJourneyDate,
                ReturnJourneyTime: retJourneyTime,
                ReturnFlightNumber: retFlightNumber,
                TerminalId: parseInt(this.state.terminalId),
                CarId: this.state.carId,
                OneWayAmount: parseFloat(this.state.oneWayAmount),
                DiscountOneWayAmount: parseFloat(this.state.discountOneWayAmount),
                ReturnAmount: parseFloat(this.state.returnAmount),
                DiscountReturnAmount: parseFloat(this.state.discountReturnAmount),
                CreatedBy: this.state.userId,
            };

            console.log('param:', param);

            axios.post(ApiURL.AddLeadDetails, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;
                    if (dataResult === 1) {
                        //cogoToast.success("Booking succeeded.", { position: 'bottom-center' });
                        this.setState({ btn: 'Proceed To Pay', redirectToPayment: true })
                    }
                    else {
                        cogoToast.warn("Booking failed! Please Try Again.", { position: 'bottom-center' });
                    }
                }
                else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }

    }

    pageRedirectToPayment = () => {
        if (this.state.redirectToPayment === true) {
            return <Redirect to="/payment-details" />
        }
    }

    render() {

        var journeyTitle = <h3></h3>
        if (!this.state.returnJourney) {
            journeyTitle = (this.state.pickOrDrop === 'P')
                ? <h3>Journey : {this.state.terminalName} <i className="fa fa-arrow-right"></i> {this.state.postCode}</h3>
                : <h3>Journey : {this.state.postCode} <i className="fa fa-arrow-right"></i> {this.state.terminalName}</h3>
        }
        else {
            journeyTitle = (this.state.pickOrDrop === 'P')
                ? <h3>Journey : {this.state.terminalName} <i className="fa fa-arrow-right"></i> {this.state.postCode} <i className="fa fa-arrow-right"></i> {this.state.terminalName}</h3>
                : <h3>Journey : {this.state.postCode} <i className="fa fa-arrow-right"></i> {this.state.terminalName} <i className="fa fa-arrow-right"></i> {this.state.postCode}</h3>
        }

        return (
            <>
                <section>
                    <div className="container">
                        <div className="min-vh-100 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12 mx-auto">
                                        <div className="py-4">
                                            <div className="d-flex align-items-center">
                                                <div className="col-md-12 col-12 col-lg-12">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="boxLogin boxLoginJD">
                                                                <h1>Journey Details</h1>
                                                                {journeyTitle}
                                                                <form>
                                                                    <div className="row px-0">
                                                                        <h4>Lead Passengers Details:</h4>
                                                                        <div className="col-md-6 col-lg-6 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="firstName" required value={this.state.firstName} autoComplete={0} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-user"></i> First Name *</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-lg-6 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="lastName" required value={this.state.lastName} autoComplete={0} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-user"></i> Last Name *</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-0">
                                                                        <div className="col-md-6 col-lg-6 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="email" value={this.state.email} disabled />
                                                                                <label><i className="fa fa-envelope"></i> Email *</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-2 col-lg-2 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <select
                                                                                    name="mobileCode"
                                                                                    className="btn btn-outline-light rounded-0 form-select"
                                                                                    value={this.state.mobileCode}
                                                                                    onChange={this.onChangeHandler}>
                                                                                    {
                                                                                        this.state.countryCodes.map((cc, i) => {
                                                                                            return (
                                                                                                <option key={i} value={cc.MobileCode}>
                                                                                                    {cc.MobileCode}
                                                                                                </option>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 col-lg-4 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="mobile" value={this.state.mobile} disabled />
                                                                                <label><i className="fa fa-phone"></i> Mobile *</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-0">
                                                                        <h4>Trip Details :</h4>
                                                                        <div className="col-md-12 col-lg-12 col-12 px-0">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="address" required value={this.state.address} onChange={this.onChangeHandler} />
                                                                                {
                                                                                    (this.state.pickOrDrop === 'P') ?
                                                                                        <label><i className="fa fa-address-card"></i> Destination Address</label>
                                                                                        :
                                                                                        <label><i className="fa fa-address-card"></i> Origin Address</label>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-0">
                                                                        <div className="inputBox">
                                                                            <input type="text" name="postCode" required value={this.state.postCode} disabled />
                                                                            {
                                                                                (this.state.pickOrDrop === 'P') ?
                                                                                    <label><i className="fa fa-map-marker"></i> Destination Postcode *</label>
                                                                                    :
                                                                                    <label><i className="fa fa-map-marker"></i> Origin Postcode *</label>
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3 col-lg-3 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="date" name="journeyDate" value={this.state.journeyDate} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-calendar"></i> Journey Date *</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 col-lg-3 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="time" name="journeyTime" value={this.state.journeyTime} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-clock-o"></i> Journey Time *</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-3 col-lg-3 col-12">
                                                                            <div className="inputBox inputBoxDevide px-1">
                                                                                <input type="text" name="noOfPassenger" required value={this.state.noOfPassenger} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-user"></i> No. of Passengers *</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 col-lg-3 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="noOfCheckInBaggage" required value={this.state.noOfCheckInBaggage} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-suitcase"></i> Check-in-Baggages</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 col-lg-3 col-12">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="noOfCabinBaggage" required value={this.state.noOfCabinBaggage} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-suitcase"></i> Cabin Baggages</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 col-lg-12 col-12 px-0">
                                                                            <div className="inputBox inputBoxDevide">
                                                                                <input type="text" name="noteForDriver" required value={this.state.noteForDriver} onChange={this.onChangeHandler} />
                                                                                <label><i className="fa fa-suitcase"></i> Note for Driver</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row px-0">
                                                                        <h4>Airline Details:</h4>

                                                                        <div className="inputBox mx-3">
                                                                            <input type="text" name="flightNumber" required value={this.state.flightNumber} onChange={this.onChangeHandler} />
                                                                            <label><i className="fa fa-plane"></i> Flight Number *</label>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (this.state.returnJourney === true) &&
                                                                        <div>
                                                                            <div className="row px-0">
                                                                                <h4>Return Details:</h4>
                                                                                <div className="inputBox mx-3">
                                                                                    <input type="date" name="returnDate" value={this.state.returnDate} onChange={this.onChangeHandler} />
                                                                                    <label><i className="fa fa-calendar"></i> Return Date *</label>
                                                                                </div>
                                                                                <div className="inputBox">
                                                                                    <input type="text" name="returnTime" value={this.state.returnTime} onChange={this.onChangeHandler} />
                                                                                    <label><i className="fa fa-clock-o"></i> Return Time *</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row px-0">
                                                                                <h4>Return Airline Details:</h4>
                                                                                <div className="inputBox mx-3">
                                                                                    <input type="text" name="returnFlightNumber" value={this.state.returnFlightNumber} onChange={this.onChangeHandler} />
                                                                                    <label><i className="fa fa-plane"></i> Return Flight Number *</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <p className="radioBtnTxt px-0">Meet & Greet Service(Yes/No)</p>
                                                                            <div className="form-check form-switch form-switch-md">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="meetAndGreetServiceTaken"
                                                                                    className="form-check-input"
                                                                                    onChange={this.onChangeHandler}
                                                                                    checked={this.state.meetAndGreetServiceTaken}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <p className="radioBtnTxt px-0">Baby seat required?(Yes/No)</p>
                                                                            <div className="form-check form-switch form-switch-md">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    name="babySeatRequired"
                                                                                    className="form-check-input"
                                                                                    onChange={this.onChangeHandler}
                                                                                    checked={this.state.babySeatRequired}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-5">
                                                                        <button className="btn btn-light btn-sm" onClick={this.submitForPayment}>
                                                                            <i className="fa fa-credit-card"></i> {this.state.btn}
                                                                        </button>
                                                                        {/* {
                                                                            (this.state.btn !== 'Processing...')
                                                                                ? <button className="btn btn-light btn-sm" onClick={this.submitForPayment}>
                                                                                    <i className="fa fa-credit-card"></i> {this.state.btn}
                                                                                </button>
                                                                                : <button className="btn btn-light btn-sm">
                                                                                    <i className="fa fa-credit-card"></i> {this.state.btn}
                                                                                </button>
                                                                        } */}
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                {this.pageRedirectToPayment()}
            </>
        );
    }
}

export default JourneyDetail;