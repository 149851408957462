import React, { Component } from 'react';

class MainFooter extends Component {
    constructor(props) {
        super(props);
    }    

    /* fbLinkClick = (e) => {
        e.preventDefault();
        var url = 'https://www.facebook.com/profile.php?id=100090982122591';
        window.open(url, '_blank');

    } */

    render() {
        return (
            <>
                <footer>
                    <div className="footer bottom_cross1">                        
                        <div className="copyright-other-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>© 2024 All Rights Reserved. Design by<a href="https://myboogy.co.uk"> www.myboogy.co.uk</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default MainFooter;