import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class Quotation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: 0,
            quotationData: [],
            redirectToLogin: false,
            redirectToJourney: false,
        }
    }

    componentDidMount() {
        this.getQuotationList();
    }

    getQuotationList = () => {
        var quoteReqData = JSON.parse(LocalStorageHelper.getQuoteRequest());
        if (quoteReqData !== null) {
            console.log(quoteReqData);
            var param = {
                TerminalId: parseInt(quoteReqData.TerminalId),
                PostCode: quoteReqData.PostCode,
                PickOrDrop: quoteReqData.PickOrDrop
            };
            axios.post(ApiURL.GetQuotationData, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    var dataResult = respData.DataResult;
                    console.log(dataResult);
                    this.setState({
                        quotationData: respData.DataResult
                    })
                }
                else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                cogoToast.error("Get Quotation List Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }
    }

    bookService = (quote, e) => {
        e.preventDefault();
        //alert(e.target.name);
        var quoteReqData = JSON.parse(LocalStorageHelper.getQuoteRequest());
        if (quoteReqData !== null) {
            quoteReqData.CarId = quote.CarId;
            quoteReqData.ReturnJourney = e.target.name === 'btnReturn' ? true : false;
            quoteReqData.OneWayRate = quote.OneWayRate;
            quoteReqData.DiscountOneWayRate = quote.DiscountOneWayRate;
            quoteReqData.ReturnRate = quote.ReturnRate;
            quoteReqData.DiscountReturnRate = quote.DiscountReturnRate;
            LocalStorageHelper.setQuoteRequest(JSON.stringify(quoteReqData));
        }

        //Check User Login
        if (JSON.parse(LocalStorageHelper.getUser()) !== null) {
            this.setState({ redirectToJourney: true })
        }
        else {
            this.setState({ redirectToLogin: true })
        }
    }

    pageRedirectToLogin = () => {
        if (this.state.redirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    pageRedirectToJourney = () => {
        if (this.state.redirectToJourney === true) {
            return <Redirect to="/journey-details" />
        }
    }

    render() {
        const quotationList = this.state.quotationData;
        const MyView = quotationList.length > 0 ?
            (quotationList.map((quote, i) => {
                const carImgURL = ApiURL.CarImageURL + "/" + quote.CarImageName;
                return (
                    <div className="card bg-success CustomShadow mt-4" key={i}>
                        <img src={carImgURL} className="card-img-top img-responsive" alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold white-heading">{quote.CarName}</h5>
                                <span><i className="fa fa-male"></i>X {quote.NoOfPassenger}</span>
                                <span><i className="fa fa-suitcase"></i>X {quote.NoOfCheckInBaggage}</span>
                                <span><i className="fa fa-shopping-bag "></i>X {quote.NoOfCabinBaggage}</span>
                                <span><i className="fa fa-info-circle"></i></span>
                                <p className="card-text">{quote.Description}</p>
                            </div>

                            <div className="cta-section">
                                <div className="productImgButtonWrap">
                                    <button name="btnOneway" className="btn btn-warning btn-sm btn-quote" onClick={(e)=>this.bookService(quote,e)}>
                                        One Way
                                        &#163;{quote.OneWayRate}&nbsp;
                                        {
                                            (quote.OneWayRate !== quote.DiscountOneWayRate) &&
                                            <span>&#163;{quote.DiscountOneWayRate}</span>
                                        }
                                    </button>

                                    <button name="btnReturn" className="btn btn-warning btn-sm btn-quote" onClick={(e)=>this.bookService(quote,e)}>
                                        Return
                                        &#163;{quote.ReturnRate}&nbsp;
                                        {
                                            (quote.ReturnRate !== quote.DiscountReturnRate) &&
                                            <span>&#163;{quote.DiscountReturnRate}</span>
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })) :
            (<div></div>)

        return (
            <>
                <section>
                    <div className="container">

                        {MyView}

                    </div>
                </section>
                {this.pageRedirectToLogin()}
                {this.pageRedirectToJourney()}
            </>
        );
    }
}

export default Quotation;