class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local
  //static BaseURL = "https://localhost:7268";

  ////Development
  //static BaseURL = "http://77.68.124.81:8080";  

  ////Production
  static BaseURL = "https://service.myboogyairporttransfer.co.uk";  

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseImageURL = this.BaseURL + "/uploadfiles/images";
  static LogoImageURL = this.BaseImageURL + "/Logo";
  static HomeBannerImageURL = this.BaseImageURL + "/HomeBanner";
  static CarImageURL = this.BaseImageURL + "/Car";
  static ClientTestimonialImageURL = this.BaseImageURL + "/Testimonial";

  ////===========================================================================
  ////API URL Section
  ////===========================================================================
  //Terminal APIs
  static GetTerminals = this.BaseURL + "/api/terminal/getTerminals";
  static GetServiceListByMaster(serviceMasterId) {
    return this.BaseURL + "/api/service/serviceListByMaster/" + serviceMasterId;
  }
  static GetQuotationData = this.BaseURL +"/api/terminal/getQuotationData";

  //Customer APIs
  static CustomerRegistration = this.BaseURL + "/api/customer/customerRegistration";

  //Auth APIs
  static UserLogin = this.BaseURL + "/api/account/login";
  static GetUserProfile = this.BaseURL + "/api/account/getUserProfile";
  static UserRegistration = this.BaseURL + "/api/account/createUser";
  //static OTPVerification = this.BaseURL + "/api/auth/validateOTP";
  //static ResendOTP = this.BaseURL + "/api/auth/resendOTP";

  //Lead APIs
  static GetAllCountryCode = this.BaseURL + "/api/lead/getCountryCodes";
  static AddLeadDetails = this.BaseURL + "/api/lead/addLeadDtls";

  //Payment APIs
  static GetPaymentDetails = this.BaseURL + "/api/payment/getPaymentDetails";

  //Booking APIs
  static bookingDtls = this.BaseURL + "/api/booking/addBookingDetails";
  static getAllBookings = this.BaseURL + "/api/booking/getAllBookings";
  static cancelBooking = this.BaseURL + "/api/booking/cancelBooking";

}

export default ApiURL;
