import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import BookingHistory from '../components/Booking/BookingHistory';
import MainFooter from '../components/common/MainFooter';

class BookingHistoryPage extends Component {
    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        return (
            <>
                <MainHeader/>

                <BookingHistory/>

                <MainFooter />
            </>
        );
    }
}

export default BookingHistoryPage;