import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router";
import HomePage from "../pages/HomePage";
import ProtectedRoute from "../pages/ProtectedRoutes";
import UnauthorizedPage from "../pages/UnauthorizedPage";
import QuotePage from "../pages/QuotePage";
import LoginPage from "../pages/LoginPage";
import JourneyDetailPage from "../pages/JourneyDetailPage";
import PaymentDetailPage from "../pages/PaymentDetailPage";
import BookingStatusPage from "../pages/BookingStatusPage";
import BookingHistoryPage from "../pages/BookingHistoryPage";

class AppRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkUserType: props.checkUserType
    }
  }

  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <HomePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
          />
          <Route
            exact
            path="/home"
            render={props => <HomePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
          />
          <Route
            exact
            path="/quote"
            render={props => <QuotePage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
          />
          <Route
            exact
            path="/login"
            render={props => <LoginPage {...props} checkUserType={this.state.checkUserType} key={Date.now()} />}
          />

          <ProtectedRoute
            exact
            path="/journey-details"
            component={JourneyDetailPage}
          />

          <ProtectedRoute
            exact
            path="/payment-details"
            component={PaymentDetailPage}
          />

          <ProtectedRoute
            exact
            path="/booking-status"
            component={BookingStatusPage}
          />

          <ProtectedRoute
            exact
            path="/booking-history"
            component={BookingHistoryPage}
          />


        </Switch>
      </Fragment>
    );
  }
}

export default AppRoute;
