import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import Quotation from '../components/quote/Quotation';
import MainFooter from '../components/common/MainFooter';

class QuotePage extends Component {
    
    render() {
        return (
            <>
                <MainHeader />

                <Quotation />

                <MainFooter />
            </>
        );
    }
}

export default QuotePage;