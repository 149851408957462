import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class BookingStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserId: 0,
            BookingId: 0,
            BookingNo: '',
            ReturnBookingId: 0,
            ReturnBookingNo: '',
            RedirectToLogin: false,
        }
    }

    componentDidMount() {
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            var bookingData = JSON.parse(LocalStorageHelper.getBooking());
            console.log(bookingData);
            if (bookingData !== null) {
                this.setState({
                    ...this.state,
                    UserId: userData.UserId,
                    BookingId: bookingData.BookingId,
                    BookingNo: bookingData.BookingNo,
                    ReturnBookingId: bookingData.ReturnBookingId,
                    ReturnBookingNo: bookingData.ReturnBookingNo,
                })
            }
        }
        else {
            let winlocation = window.location.pathname;
            console.log(window.location.href);
            LocalStorageHelper.SetLastRedirectPath(winlocation);
            this.setState({ RedirectToLogin: true })
        }
    }

    pageRedirectToLogin = () => {
        if (this.state.RedirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    render() {
        return (
            <>
                <section>
                    {
                        this.state.BookingNo !== '' ?

                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-5">
                                        <div className="message-box success">
                                            <i className="fa fa-check-circle" aria-hidden="true"></i>
                                            <h2>Booking Confirmed!</h2>
                                            <h3>Your Booking Id is : {this.state.BookingNo}</h3>
                                            {
                                                (this.state.ReturnBookingNo!=='') &&
                                                <h3>Return Booking Id is : {this.state.ReturnBookingNo}</h3>
                                            }
                                            <p>
                                                Thank you for your payment. We will contact you with more details shortly. 
                                                Feel free to explore or reach out if you have any further questions or need assistance. 
                                                We're here to help!
                                            </p>
                                            <br />
                                            <Link to="/">Back to Home</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-5">
                                        <div className="message-box success failed">
                                            <i className="fa fa-times-circle" aria-hidden="true"></i>
                                            <h2>Booking Failed!</h2>
                                            <p>
                                                Please Try again later. Thank you for showing interest in our portal! 
                                                Feel free to explore or reach out if you have any further questions or need assistance. 
                                                We're here to help!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    }
                </section>
                {this.pageRedirectToLogin()}
            </>
        );
    }
}

export default BookingStatus;