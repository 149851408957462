import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import creditcardImage from '../../assets/images/credit-card.png';
import paypalImage from '../../assets/images/paypal.png';
import driverImage from '../../assets/images/driver.png';
import PayPalButton from '../paypal/PayPalButton';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import StripeCheckoutButton from '../stripe/StripeCheckoutButton';
import { Redirect } from 'react-router';

import { CardImg } from 'react-bootstrap';

class PaymentDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            custUserId: 0,
            serviceDetail: null,
            terminalId: 0,
            bookingId: 0,
            bookingNo: '',
            returnBookingId: 0,
            returnBookingNo: '',
            bookingAmount: 0,
            dropOffFees: 0,
            parkingFees: 0,
            babySeat: 0,
            meetAndGreet: 0,
            netAmount: 0,
            paymentData: null,
            isAgreedTnC: false,
            cardType: '',
            cardNo: '',
            nameOnCard: '',
            cardExpiry: '',
            cardCVC: '',
            //orderResponse: null,
            paymentTranId: '',
            gatewayPayMode: '',
            gatewayPayStatus: '',
            gatewayResponse: null,
            paymentGateway: '',
            redirectToBookingStatus: false
        };
    }

    componentDidMount() {
        let self = this;
        /* Get User Profile Data */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({ custUserId: userData.UserId }, () => self.getPaymentData())
        }
        else {
            /* TODO: Redirect to the Login Page */
        }
    }

    getPaymentData = () => {
        var param = { CustomerUserId: this.state.custUserId };
        axios.post(ApiURL.GetPaymentDetails, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                if (dataResult !== null) {
                    const svcDetail = {
                        description: 'Airport Cab Fare',
                        price: dataResult.NetAmount
                    };
                    this.setState({
                        serviceDetail: svcDetail,
                        bookingAmount: dataResult.BookingAmount,
                        dropOffFees: dataResult.DropOffFees,
                        parkingFees: dataResult.ParkingFees,
                        babySeat: dataResult.BabySeat,
                        meetAndGreet: dataResult.MeetAndGreet,
                        netAmount: dataResult.NetAmount
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }
        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    agreedTnCHandler = (e) => {
        let isAgreed = e.target.checked ? true : false;
        this.setState({ isAgreedTnC: isAgreed })
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    paymentByStripe = (token) => {
        let self = this;
        console.log('resp: ', token);
        if (token !== null) {
            self.setState({
                gatewayResponse: JSON.stringify(token),
                paymentTranId: token.id,
                netAmount: self.state.bookingAmount,
                gatewayPayMode: 'Card',
                paymentGateway:'STRIPE',
                gatewayPayStatus: 'COMPLETED'
            }, () => self.addPayment())
        }
    }

    paymentByPayPal = (resp) => {
        let self = this;
        console.log('resp: ', resp);
        if (resp !== null) {
            let amount = resp.purchase_units[0].amount.value;
            self.setState({
                gatewayResponse: JSON.stringify(resp),
                paymentTranId: resp.id,
                netAmount: amount,
                gatewayPayMode: 'PayPal',
                paymentGateway:'PAYPAL',
                gatewayPayStatus: 'COMPLETED'
            }, () => self.addPayment())
        }
    }

    paymentByCash = (e) => {
        e.preventDefault();
        let self = this;
        self.setState({
            paymentTranId: self.state.paymentTranId,
            netAmount: self.state.bookingAmount,
            gatewayPayMode: 'Cash',
            gatewayPayStatus: 'COMPLETED'
        }, () => self.addPayment())

    }

    addPayment = () => {
        var param = {
            CustomerUserId: this.state.custUserId,
            Amount: this.state.netAmount,
            PaymentTransactionId: this.state.paymentTranId,
            GatewayPayMode: this.state.gatewayPayMode,
            GatewayPayStatus: this.state.gatewayPayStatus,
            GatewayResponse: this.state.gatewayResponse,
            PaymentGateway: this.state.paymentGateway
        };
        axios.post(ApiURL.bookingDtls, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                console.log(dataResult);
                if (dataResult !== null && dataResult.BookingId > 0) {
                    LocalStorageHelper.setBooking(JSON.stringify(dataResult));
                    this.setState({
                        bookingId: dataResult.BookingId,
                        bookingNo: dataResult.BookingNo,
                        returnBookingId: dataResult.ReturnBookingId,
                        returnBookingNo: dataResult.ReturnBookingNo,
                        redirectToBookingStatus: true
                    })
                }
                else {
                    cogoToast.error("Sorry! Booking failed.", { position: 'bottom-center' });
                    this.setState({ redirectToBookingStatus: true })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }
        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    pageRedirectToBookingStatus = () => {
        if (this.state.redirectToBookingStatus === true) {
            return <Redirect to="/booking-status" />
        }
    }

    render() {
        return (
            <>
                <section>
                    <div className="container">
                        <h1 className="h3 mt-5">Payment</h1>
                        <div className="row">
                            {/* <!-- Left --> */}
                            <div className="col-lg-9">
                                <div className="accordion">
                                    {/* <!-- Stripe --> */}
                                    <div className="accordion-item mb-3">
                                        <h2 className="h5 px-4 py-3 accordion-header d-flex justify-content-between align-items-center">
                                            <div className="form-check w-100 collapsed" data-bs-toggle="collapse" data-bs-target="#collapseCC" aria-expanded="false">
                                                <input className="form-check-input" type="radio" name="payment" id="payment1" />
                                                <label className="form-check-label pt-1" htmlFor="payment1">
                                                    Payment By Card
                                                </label>
                                            </div>
                                            <span>
                                                <img src={creditcardImage} />
                                            </span>
                                        </h2>
                                        <div id="collapseCC" className="accordion-collapse collapse show" data-bs-parent="#accordionPayment">
                                            <div className="accordion-body">
                                                <StripeCheckoutButton
                                                    service={this.state.serviceDetail}
                                                    disabled={!this.state.isAgreedTnC}
                                                    paymentByStripe={this.paymentByStripe}                                                    
                                                    stripeKey={process.env.REACT_APP_STRIPE_KEY}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- PayPal --> */}
                                    <div className="accordion-item mb-3 border">
                                        <h2 className="h5 px-4 py-3 accordion-header d-flex justify-content-between align-items-center">
                                            <div className="form-check w-100 collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePP" aria-expanded="false">
                                                <input className="form-check-input" type="radio" name="paymentOption" />
                                                <label className="form-check-label pt-1" htmlFor="payment2">
                                                    Payment By PayPal
                                                </label>
                                            </div>
                                            <span>
                                                <img src={paypalImage} />
                                            </span>
                                        </h2>
                                        <div id="collapsePP" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="px-2 col-lg-6 mb-3">
                                                    {(this.state.serviceDetail !== null) &&
                                                        <PayPalScriptProvider options={{
                                                            //"client-id": "AXwfCV1Aas7wi4y4vkpmQYdcpJXm4syqZXhwgcf1jLZDjgI7AlNLNU0er775XHplEpqIT29udn7Je2N0",
                                                            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                                            "currency": "GBP"
                                                        }}>
                                                            <PayPalButton
                                                                service={this.state.serviceDetail}
                                                                disabled={!this.state.isAgreedTnC}
                                                                paymentByPayPal={this.paymentByPayPal}
                                                            />
                                                        </PayPalScriptProvider>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Pay to Driver --> */}
                                    <div className="accordion-item mb-3 border">
                                        <h2 className="h5 px-4 py-3 accordion-header d-flex justify-content-between align-items-center">
                                            <div className="form-check w-100 collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePD" aria-expanded="false">
                                                <input className="form-check-input" type="radio" name="paymentOption" />
                                                <label className="form-check-label pt-1" htmlFor="payment3">
                                                    Pay to Driver By Cash
                                                </label>
                                            </div>
                                            <span>
                                                <img src={driverImage} />
                                            </span>
                                        </h2>
                                        <div id="collapsePD" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="px-2 col-lg-11 mb-3">
                                                    <div className='row'>
                                                        <div className="col-lg-2">
                                                            <div className="mb-3">
                                                                <button
                                                                    className={`btn btn-dark w-100 mt-2 ${this.state.isAgreedTnC ? '' : 'disabled'}`}
                                                                    onClick={this.paymentByCash}>Pay Now</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Right --> */}
                            <div className="col-lg-3">
                                <div className="paymentCard position-sticky top-0">
                                    <div className="p-3">
                                        <h4 className="paymentCard-title mb-3">Payment Details</h4>
                                        <div className="d-flex justify-content-between mb-1 small">
                                            <span><strong>Fare</strong></span>
                                            <span><strong>
                                                <i className="fa fa-gbp" aria-hidden="true"></i>{this.state.bookingAmount}</strong>
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-between mb-1 small">
                                            <span><strong>Add-on Charges</strong></span>
                                        </div>
                                        <div className="px-1">
                                            <div className="d-flex justify-content-between mb-1 small">
                                                <span>Drop off Fees</span>
                                                <span>
                                                    <i className="fa fa-gbp" aria-hidden="true"></i>{this.state.dropOffFees}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between mb-1 small">
                                                <span>Parking Fees (30 minutes)</span>
                                                <span className="text-danger">
                                                    <i className="fa fa-gbp" aria-hidden="true"></i>{this.state.parkingFees}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between mb-1 small">
                                                <span>Baby Seat</span>
                                                <span className="text-danger">
                                                    <i className="fa fa-gbp" aria-hidden="true"></i>{this.state.babySeat}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between mb-1 small">
                                                <span>Meet & Greet Service</span>
                                                <span className="text-danger">
                                                    <i className="fa fa-gbp" aria-hidden="true"></i>{this.state.meetAndGreet}
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between mb-4 small">
                                            <span>TOTAL</span>
                                            <strong className="text-dark">
                                                <i className="fa fa-gbp" aria-hidden="true"></i>{this.state.netAmount}
                                            </strong>
                                        </div>
                                        <div className="form-check mb-1 small">
                                            <input
                                                type="checkbox"
                                                name="isAggreed"
                                                className="form-check-input"
                                                onChange={this.agreedTnCHandler}
                                                checked={this.state.isAggreed}
                                            />
                                            <label className="form-check-label px-2" htmlFor="tnc">
                                                I agree to the <a href="#">terms and conditions</a>
                                            </label>
                                        </div>
                                        {/* <button
                                            className={`btn btn-dark w-100 mt-2 ${isAgreedTnC ? '' : 'disabled'}`}
                                            onClick={this.confirmBookingHandler}>
                                            Confirm Booking
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.pageRedirectToBookingStatus()}
            </>
        );
    }
}

export default PaymentDetail;