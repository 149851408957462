import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";

class Unauthorized extends Component {    
    render() {
        const unauthorizedImgURL = ApiURL.BaseImageURL + "/access-denied.webp";
        return (
            <>
                <section className="banner-inner">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">Unauthorized Access</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="odr-confrm">
                    <div className="container">
                        <div className="row">
                            <div className="odr-confrm-wrap text-center">
                                <img src={unauthorizedImgURL} alt="..."  style={{width: '30%'}}/>
                                <h5>Sorry! You are not authorized to access this page.</h5>
                                <Link to="/login">Authorize Login</Link> 
                            </div>
                        </div>
                    </div>
                </section>
            </>            
        );
    }
}

export default Unauthorized;