import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LocalStorageHelper from '../LocalStorageHelper/LocalStorageHelper';

class ProtectedRoute extends Component {

    render() {
        const Component = this.props.component;
        const isAuthenticated = JSON.parse(LocalStorageHelper.getUser()) !== null ? true : false;
        return isAuthenticated ?
            <Component /> :
            <Redirect to={{ pathname: '/login' }} />
    }
}

export default ProtectedRoute;