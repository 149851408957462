import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import MainFooter from '../components/common/MainFooter';
import JourneyDetail from '../components/Journey/JourneyDetail';

class JourneyDetailPage extends Component {    
    render() {
        return (
            <>
                <MainHeader />

                <JourneyDetail/>

                <MainFooter />
            </>
        );
    }
}

export default JourneyDetailPage;