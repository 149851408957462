import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import banner1 from '../../assets/images/banner-1.jpeg';
import banner2 from '../../assets/images/banner-2.jpeg';
import banner3 from '../../assets/images/banner-3.jpeg';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';


class HomeBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'P',
            terminalData: [],
            source1: '0',
            destination1: '',
            source2: '',
            destination2: '0',
            redirectToQuotation: false
        }
    }

    componentDidMount() {
        window.scroll(0, 0);
        this.getTerminalData();
    }

    getTerminalData = () => {
        axios.get(ApiURL.GetTerminals).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                this.setState({
                    terminalData: dataResult
                })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    handleOptionChange = (event) => {
        this.setState({
            selectedOption: event.target.value
        });
    };

    onChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    getQuotation = (event) => {
        event.preventDefault();
        var { source1, destination1, source2, destination2, selectedOption } = this.state;
        console.log(selectedOption);
        let terminalName = '';
        if (selectedOption === 'P') {
            terminalName = this.state.terminalData.filter(x => x.TerminalId = source1)[0]?.TerminalName;
        }
        else {
            terminalName = this.state.terminalData.filter(x => x.TerminalId = source2)[0]?.TerminalName;
        }        
        var quoteReqData = {
            TerminalId: selectedOption === 'P' ? source1 : destination2,
            TerminalName: terminalName,
            PostCode: selectedOption === 'P' ? destination1 : source2,
            PickOrDrop: selectedOption,
            ReturnJourney: false
        };

        //console.log(quoteReqData);
        LocalStorageHelper.setQuoteRequest(JSON.stringify(quoteReqData));

        this.setState({
            ... this.state,
            redirectToQuotation: true
        })
    }

    redirectToQuotationPage = () => {
        if (this.state.redirectToQuotation === true) {
            return <Redirect to="/quote" />
        }
    }

    render() {
        const { selectedOption, source1, destination2, terminalData } = this.state;

        return (
            <>
                <main id="main">
                    <section id="main-carousel">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col px-0">
                                    <div className="carousel slide kb-carousel carousel-fade" id="carouselKenBurns" data-bs-ride="carousel">
                                        {/* <!-- Carousel Items --> */}
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={banner1} className="d-block w-100" alt="Slide 1" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={banner2} className="d-block w-100" alt="Slide 2" />
                                            </div>
                                            <div className="carousel-item">
                                                <img src={banner3} className="d-block w-100" alt="Slide 3" />
                                            </div>
                                            <div className="col-md-4 col-lg-4">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="box">
                                                            <h2>Get instant quote</h2>
                                                            <form>
                                                                <div className="btn-group" role="group" aria-label="Basic radio toggle button">
                                                                    <label 
                                                                        className={selectedOption === 'P' ? "btn btn-outline-success selected-toggle-btn": "btn btn-outline-success"}>
                                                                        <input
                                                                            type="radio"
                                                                            className="btn-check"
                                                                            value="P"
                                                                            checked={selectedOption === 'P'}
                                                                            onChange={this.handleOptionChange}
                                                                        />
                                                                        Pickup
                                                                    </label>
                                                                    <br />
                                                                    <label className={selectedOption === 'D' ? "btn btn-outline-success selected-toggle-btn": "btn btn-outline-success"}>
                                                                        <input
                                                                            type="radio"
                                                                            className="btn-check"
                                                                            value="D"
                                                                            checked={selectedOption === 'D'}
                                                                            onChange={this.handleOptionChange}
                                                                        />
                                                                        Drop
                                                                    </label>
                                                                </div>

                                                                {
                                                                    (selectedOption === 'P') ?
                                                                        <>
                                                                            <div className="inputBox mt-3">
                                                                                <select name="source1" className="form-select" defaultValue={source1} onChange={this.onChangeHandler}>
                                                                                    <option value='0'>Select Airport Terminal</option>
                                                                                    {
                                                                                        terminalData.map((item, i) => {
                                                                                            return (<option key={i} value={item.TerminalId}>{item.TerminalName}</option>)
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="inputBox-forHome mb-3">
                                                                                <input type="text" name="destination1" autoComplete="off" placeholder="Enter Destination Postcode" onChange={this.onChangeHandler} />
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="inputBox-forHome mt-1">
                                                                                <input type="text" name="source2" autoComplete="off" placeholder="Enter Source Postcode" onChange={this.onChangeHandler} />
                                                                            </div>
                                                                            <div className="inputBox mb-4">
                                                                                <select name="destination2" className="form-select" defaultValue={destination2} onChange={this.onChangeHandler}>
                                                                                    <option value='0'>Select Airport Terminal</option>
                                                                                    {
                                                                                        terminalData.map((item, i) => {
                                                                                            return (<option key={i} value={item.TerminalId}>{item.TerminalName}</option>)
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </>
                                                                }

                                                                <button className="btn btn-success px-4" onClick={this.getQuotation}>Quote</button>&nbsp;
                                                                <button className="btn btn-light px-4">Reset</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!-- Carousel Arrows --> */}
                                        <button className="carousel-control-prev kb-control-prev" type="button" data-bs-target="#carouselKenBurns" data-bs-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Previous</span>
                                        </button>
                                        <button className="carousel-control-next kb-control-next" type="button" data-bs-target="#carouselKenBurns" data-bs-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span className="visually-hidden">Next</span>
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                {this.redirectToQuotationPage()}
            </>
        );
    }
}

export default HomeBanner;   